import { useState } from 'react';
import { DropdownMenu } from './ui/DropDown/DropdownMenu';
import { DropdownMenuTrigger } from './ui/DropDown/DropdownMenuTrigger';
import { DropdownMenuContent } from './ui/DropDown/DropdownMenuContent';
import ToggleMenuBox from './ui/ToggleMenuBox';
import NavigationApps from './ui/NavigationApps';
import { Button } from './ui/Button';
import { useSubMenu } from './SubMenuContext';

interface TopBarProps {
	toggleSidebar?: (toggle?: boolean) => void;
	app: string;
}

const TopBar = ({ app }: TopBarProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const { setSideBarOpen, isSidebarOpen} = useSubMenu()

	const handleOnOpen = (state: boolean) => {
		setIsOpen(state);
	};

	return (
		<div className="w-full h-[60px]">
			<div className="bg-white w-full h-full border-b border-gray-200 flex items-center !px-4 gap-x-2 cursor-pointer">
				<Button id='sidebar_trigger' onClick={() => setSideBarOpen(!isSidebarOpen)} className="w-9 h-9 p-0">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
						<path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
					</svg>
				</Button>
				<div>
					<DropdownMenu isOpen={isOpen} setIsOpen={handleOnOpen}>
						<DropdownMenuTrigger>
							<ToggleMenuBox app={app} isOpen={isOpen} />
						</DropdownMenuTrigger>

						<DropdownMenuContent className="pr-0.5">
							{/* <HeaderApps /> */}

							<NavigationApps />
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			</div>
		</div>
	);
};

export default TopBar;