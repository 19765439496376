import React, { ReactNode, cloneElement, isValidElement, ReactElement, HTMLAttributes, Children, useState, useEffect, useRef } from 'react';
import { useSubMenu } from '../SubMenuContext';
import { twMerge } from 'tailwind-merge';
import ItemSub from '../ItemSub';
import { makeID } from '../lib/utils';
import { motion } from 'framer-motion';
import Modal from './Modal/Modal';
import { DropdownMenu } from './DropDown/DropdownMenu';

interface ItemMenuProps extends HTMLAttributes<HTMLElement> {
	id?: string;
	children: ReactNode;
	asChild?: boolean;
	isActive?: boolean;
	isSidebarOpen?: boolean;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	setSidebarOpen?: (toggle: boolean) => void;
	className?: string;
	classeAtiva?: string;
}

const ItemMenu = ({ id, children, asChild = false, isActive = false, setSidebarOpen, classeAtiva = 'bg-gray-900 text-white', ...props }: ItemMenuProps) => {
	const { toggle, toggleSub, state, setMenuId: setMenuActive, closeThirdMenus, clearSub, registerItemRef } = useSubMenu();
	const [menuRandomID, setMenuRandomID] = useState('');
	const itemRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		const id = makeID();
		setMenuRandomID(id);
		registerItemRef(id, itemRef.current);
	}, []);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		const hasItemSub = Children.toArray(children).some((child) => isValidElement(child) && child.type === ItemSub);

		props.onClick?.(event);

		if (hasItemSub) {
			if (toggleSub) {
				if (state?.subID === menuRandomID) {
					toggleSub('');
					setMenuActive?.(menuRandomID);
					return;
				}

				setSidebarOpen?.(true);
				toggleSub(menuRandomID);
				setMenuActive?.(menuRandomID);
			}
			closeThirdMenus?.();
			return;
		}

		clearSub?.();
		toggleSub?.('');

		closeThirdMenus?.();

		if (id) {
			setSidebarOpen?.(false);
			toggle(id!);
		}

		setMenuActive?.(menuRandomID);
	};

	const isSubMenuOpen = state?.subID === menuRandomID;

	let elAtivo = isActive;

	if (state?.menuID) {
		elAtivo = state?.menuID === menuRandomID;
	}

	const defaultClasses = `group rounded-md text-sm font-semibold items-center break-normal whitespace-nowrap gap-3 h-9 w-full min-w-9 flex flex-row py-2 px-1.5 transition focus-visible:outline-0 overflow-hidden bg-white text-gray-900  ${isActive ? 'bg-gray-100 active' : ''} ${elAtivo ? `${twMerge('bg-gray-900 text-white', `${classeAtiva}`)} active` : 'hover:bg-gray-100'}`;
	const itemsSubClasses = `overflow-hidden flex flex-col gap-1`;

	const mainChildren: ReactNode[] = [];
	const itemSubs: ReactNode[] = [];

	Children.forEach(children, (child) => {
		if (isValidElement(child) && child.type && ((child as ReactElement).type === ItemSub || (child as ReactElement).type === Modal || (child as ReactElement).type === DropdownMenu)) {
			itemSubs.push(child);
		} else {
			mainChildren.push(child);
		}
	});

	if (asChild && mainChildren.length === 1 && isValidElement(mainChildren[0])) {
		const mainChild = mainChildren[0] as ReactElement;
		const mergedClasses = twMerge(defaultClasses, `${(mainChild as React.ReactElement<ItemMenuProps>).props.className || ''} ${props.className || ''}`);

		const childProps = {
			onClick: (event: React.MouseEvent<Element, MouseEvent>) => {
				if ((mainChild as React.ReactElement<ItemMenuProps>).props.onClick) {
					(mainChild as React.ReactElement<ItemMenuProps>).props?.onClick?.(event as React.MouseEvent<HTMLElement, MouseEvent>);
				}
				handleClick(event as React.MouseEvent<HTMLElement>);
			},
			...props,
			draggable: false,
			className: mergedClasses,
		};

		return (
			<div>
				{cloneElement(mainChild, childProps)}
				<motion.div initial={{ maxHeight: 0 }} animate={{ maxHeight: isSubMenuOpen ? itemSubs.length * 40 : 0 }} className={itemsSubClasses}>
					{itemSubs}
				</motion.div>
			</div>
		);
	}

	return (
		<div>
			<button ref={itemRef} {...props} onClick={handleClick} className={twMerge(defaultClasses, props.className)}>
				{mainChildren}
			</button>
			<motion.div initial={{ maxHeight: 0, margin: 0, opacity: 0 }} animate={{ maxHeight: isSubMenuOpen ? itemSubs.length * 40 : 0, marginBottom: isSubMenuOpen ? 4 : 0, marginTop: isSubMenuOpen ? 4 : 0, opacity: isSubMenuOpen ? 1 : 0 }} className={itemsSubClasses}>
				{itemSubs}
			</motion.div>
		</div>
	);
};

export default ItemMenu;
