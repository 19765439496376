import { useHistory, useLocation } from "react-router-dom";
import {
  LabelMenu,
  Container,
  Body,
  SectionLeft,
  SectionRight,
  Rodape,
  BoxOptions,
  Contents,
  SectionLogo,
} from "./styled";
import Logo from "../../../../assets/imgs/logo01.png";
import animation from "../../../../assets/animation/dijitalLogin.webm";
import { useEffect, useState } from "react";
import { isVisibleScript } from "../../../../utils/displayScript";
import { useDispatch, useSelector } from "react-redux";
import { translations } from "../../../../utils/language";
import Select from "react-select";

const Page = (props) => {
  const selectedMenu = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const languageState = useSelector((state) => state.reducerLanguage);
  const [language, setLanguage] = useState("");
  const [countryValue, setCountryValue] = useState("");
  const options = [
    {
      value: "BR",
      label: (
        <div>
          <img
            src="https://www.gov.br/planalto/pt-br/conheca-a-presidencia/acervo/simbolos-nacionais/bandeira/bandeiragrande.jpg"
            height="30px"
            width="40px"
          />
        </div>
      ),
      language: "pt",
    },
    {
      value: "MX",
      label: (
        <div>
          <img
            src="https://s4.static.brasilescola.uol.com.br/be/2022/10/bandeira-do-mexico.jpg"
            height="30px"
            width="40px"
          />
        </div>
      ),
      language: "es",
    },
    {
      value: "AR",
      label: (
        <div>
          <img
            src="https://static.todamateria.com.br/upload/ba/nd/bandeira_argentina_bb.jpg"
            height="30px"
            width="40px"
          />
        </div>
      ),
      language: "es",
    },
  ];

  useEffect(() => {
    const storageLanguage = localStorage.getItem("country");
    if (storageLanguage === "BR") {
      setCountryValue({
        value: "BR",
        label: (
          <div>
            <img
              src="https://www.gov.br/planalto/pt-br/conheca-a-presidencia/acervo/simbolos-nacionais/bandeira/bandeiragrande.jpg"
              height="30px"
              width="40px"
            />
          </div>
        ),
        language: "pt",
      });
    } else if (storageLanguage === "MX") {
      setCountryValue({
        value: "MX",
        label: (
          <div>
            <img
              src="https://s4.static.brasilescola.uol.com.br/be/2022/10/bandeira-do-mexico.jpg"
              height="30px"
              width="40px"
            />
          </div>
        ),
        language: "es",
      });
    } else if (storageLanguage === "AR") {
      setCountryValue({
        value: "ar",
        label: (
          <div>
            <img
              src="https://static.todamateria.com.br/upload/ba/nd/bandeira_argentina_bb.jpg"
              height="30px"
              width="40px"
            />
          </div>
        ),
        language: "es",
      });
    } else {
      setCountryValue({
        value: "BR",
        label: (
          <div>
            <img
              src="https://www.gov.br/planalto/pt-br/conheca-a-presidencia/acervo/simbolos-nacionais/bandeira/bandeiragrande.jpg"
              height="30px"
              width="40px"
            />
          </div>
        ),
        language: "pt",
      });
    }
  }, [languageState]);

  // useEffect(() => {
  //     document.getElementById('wa__btn_popup_chat').style.display = "none";
  //     if (document.getElementById('wa__btn_popup_chat') != null) {
  //         document.getElementById('wa__btn_popup_chat').style.zIndex = "-99999";
  //     }
  //     if (document.getElementById('productstashSelector') != null) {
  //         document.getElementById('productstashSelector').style.display = "none";
  //     }
  // }, [selectedMenu])
  // useEffect(() => {
  //     document.getElementById('wa__btn_popup_chat').style.display = "none";
  //     if (document.getElementById('wa__btn_popup_chat') != null) {
  //         document.getElementById('wa__btn_popup_chat').style.zIndex = "-999999";
  //     }
  //     if (document.getElementById('productstashSelector') != null) {
  //         document.getElementById('productstashSelector').style.display = "none";
  //     }
  // }, [])

  useEffect(() => {
    const storageLanguage = localStorage.getItem("language");
    if (storageLanguage) {
      setLanguage(storageLanguage);
      dispatch({
        type: "SAGA_LIST_LANGUAGE",
        payload: {
          language: storageLanguage,
        },
      });
    } else {
      setLanguage("pt");
      dispatch({
        type: "SAGA_LIST_LANGUAGE",
        payload: {
          language: "pt",
        },
      });
    }
  }, [localStorage, dispatch]);

  useEffect(() => {
    const animation = document.getElementById("animation");
    animation.addEventListener(
      "mouseenter",
      function (e) {
        this.play();
      },
      false
    );

    // const script2 = document.createElement("script")
    // script2.type = "text/javascript"
    // 
    // script2.defer = "defer"
    // document.body.appendChild(script2);

    function handleResize() { }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // const script1 = document.createElement("script");
    // script1.innerHTML = 'var ps_config = {workspace_id : "7efd6fdd-42f5-479d-b2d7-2bf5e61f0138"};'
    // document.body.appendChild(script1);

    // const script2 = document.createElement("script")
    // script2.type = "text/javascript"
    // 
    // script2.defer = "defer"
    // document.body.appendChild(script2);

    // 
    // var elemento = document.getElementById("root");
    // 
  }, []);

  return (
    <Container>
      <Body>
        <SectionLeft>
          <SectionLogo>
            <span id="box-animation">
              {/* <img style={{ cursor: "pointer" }} src={Logo} onClick={() => { history.push("/") }} /> */}
              <video
                style={{ cursor: "pointer" }}
                id="animation"
                width="75"
                muted
              >
                <source src={animation} type="video/webm" />
              </video>
            </span>
          </SectionLogo>
          {/* <span>
                        <label onClick={() => { window.open('https://empreender.com.br/', '_blank'); }}>EMPREENDER</label>
                    </span> */}
          <a
            href="https://empreender.com.br/"
            style={{
              display: "flex",
              width: "fit-content",
              margin: "11px auto",
              padding: "0",
            }}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://empreender.nyc3.cdn.digitaloceanspaces.com/static/empreender.svg"
              alt="empreender-logo"
              style={{
                position: "unset",
                width: "fit-content",
                height: "9px",
                margin: "0",
              }}
            ></img>
          </a>
        </SectionLeft>
        <SectionRight>
          <BoxOptions>
            {props.admin ?
              <label style={{fontSize: "24px", fontWeight: "800", color: "#1b76ff"}}>Login de administrador</label> :
              <>
                <LabelMenu
                  onClick={() => {
                    history.push("/login");
                  }}
                  selectedMenu={selectedMenu.pathname === "/login" ? true : false}
                >
                  Login
                </LabelMenu>
                <LabelMenu
                  onClick={() => {
                    history.push("/cadastro");
                  }}
                  selectedMenu={
                    selectedMenu.pathname === "/cadastro" ? true : false
                  }
                >
                  {translations["Registrar"][language]}
                </LabelMenu>
                <div style={{ marginLeft: 90 }}>
                  <Select
                    options={options}
                    styles={{ marginLeft: 80 }}
                    value={countryValue}
                    onChange={(a) => {
                      setLanguage(a.language);
                      setCountryValue(a);
                      localStorage.setItem("language", a.language);
                      localStorage.setItem("country", a.value);
                      dispatch({
                        type: "SAGA_LIST_LANGUAGE",
                        payload: {
                          language: a.language,
                        },
                      });
                    }}
                  />
                </div>
              </>}

            {/* {(language === "pt" || language === null) && (
              <p
                style={{
                  cursor: "pointer",
                  marginLeft: 80,
                  marginBottom: 0,
                }}
                src="https://www.gov.br/planalto/pt-br/conheca-a-presidencia/acervo/simbolos-nacionais/bandeira/bandeiragrande.jpg"
                alt="bandeira"
                onClick={() => {
                  setLanguage("es");
                  localStorage.setItem("language", "es");
                  dispatch({
                    type: "SAGA_LIST_LANGUAGE",
                    payload: {
                      language: "es",
                    },
                  });
                }}
              >
                Português
                <TfiExchangeVertical style={{ marginLeft: 10 }} />
              </p>
            )} */}
            {/* {language === "es" && (
              <div>
                <p
                  style={{
                    cursor: "pointer",
                    marginLeft: 100,
                    marginBottom: 0,
                  }}
                  src="https://s4.static.brasilescola.uol.com.br/be/2022/10/bandeira-do-mexico.jpg"
                  alt="bandeira"
                  onClick={() => {
                    setLanguage("pt");
                    localStorage.setItem("language", "pt");
                    dispatch({
                      type: "SAGA_LIST_LANGUAGE",
                      payload: {
                        language: "pt",
                      },
                    });
                  }}
                >
                  Español
                  <TfiExchangeVertical style={{ marginLeft: 10 }} />
                </p>
              </div>
            )} */}
          </BoxOptions>
          {props.admin ?
            <div style={{marginBottom: "20px"}}>
              {props.children}
            </div> :
            <Contents>{props.children}</Contents>}
          {props.admin ? null :
            <Rodape>
              <a
                className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe"
                href="https://www.iubenda.com/privacy-policy/88589789"
                title="Política de Privacidade"
              >
                {translations["Política de Privacidade"][language]}
              </a>
              <a
                href="https://www.iubenda.com/privacy-policy/88589789/cookie-policy"
                className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe"
                title="Política de Cookies"
              >
                {translations["Política de Cookie"][language]}
              </a>
              <a
                href="https://www.iubenda.com/termos-e-condicoes/88589789"
                className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe"
                title="Termos e Condições"
              >
                {translations["Termos e condições"][language]}
              </a>
            </Rodape>}
        </SectionRight>
      </Body>
      {isVisibleScript(false)}
    </Container>
  );
};

export default Page;
