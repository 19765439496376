import styled from "styled-components";

export const Container = styled.div`
  background-color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 999999999999999999;

  @media (max-width: 990px) {
  }
`;

export const SectionLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 300px;
  width: 300px; */
  margin-top: -20px;

  /* @media screen and (max-width: 768px) {
        display: none;
    } */
  span {
    height: 200%;
    width: 200%;
    display: flex;
    justify-content: center;

    img {
      max-width: 75px;
    }

    video {
      clip-path: inset(0.1px 0.1px);
      height: 400px;
      width: 400px;
    }
  }
`;

export const Body = styled.div`
  background-color: white;
  display: flex;
  width: 900px;
  height: 500px;
  //border: 1px solid gray;
  border-radius: 5px;

  box-shadow: 0 0 2em #dcdbda;

  @media (max-width: 990px) {
    flex-direction: column;
    width: 100%;
    box-shadow: none;
    height: 100%;
    //background-color: yellow;
    padding-top: 25px;
  }
`;

export const Rodape = styled.div`
  display: flex;
  height: 30px;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 360px) {
    margin-top: 25px;
  }

  a {
    font-size: 9px;
    color: gray;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    :hover {
      text-decoration-line: underline;
    }
  }
`;

export const SectionLeft = styled.div`
  background-color: ${(props) => props.theme.colors.layout.secondary};
  display: flex;
  width: 400px;
  height: 100%;
  border-radius: 5px 0px 0px 5px;
  flex-direction: column;

  @media (max-width: 990px) {
    background-color: white;
    width: 100%;
  }

  div {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    img {
      max-height: 300px;
    }
  }
  span {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    label {
      font-family: "Roboto";
      font-size: 12px;
      font-weight: bold;
      cursor: pointer;
      letter-spacing: 3px;
      color: #979797;
      :hover {
        text-decoration: underline;
      }
    }
  }
`;

export const SectionRight = styled.div`
  background-color: white;
  display: flex;
  width: 500px;
  height: 100%;
  border-radius: 0px 5px 5px 0px;
  flex-direction: column;
  padding: 0px 75px;
  justify-content: center;

  @media (max-width: 990px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    padding: 0px 20px;
  }
`;

export const BoxOptions = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  margin: 20px 0px 0px 0px;
`;

export const LabelMenu = styled.label`
  display: flex;
  margin-right: 20px;
  padding-bottom: 5px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.selectedMenu ? "2.5px solid #dc3545" : "none"};
  margin-top: ${(props) => props.selectedMenu && "2.5px"};
  font-weight: ${(props) => props.selectedMenu && "bold"};

  :hover {
    border-bottom: 2.5px solid #dc3545;
    margin-top: 2.5px;
  }
`;

export const Contents = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
