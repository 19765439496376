import { all, takeLatest } from "redux-saga/effects";

import { logar, concluirLogarPlus, register, password, logarAdmin, } from "../modules/login/redux/sagas/sagaLogin";
import { integrate, platforms, deleteIntegration, getUserId, deleteIntegrationNew, integrateNew, } from "../modules/integrations/redux/sagas/sagaIntegration";
import { listCupons, updateCupom, deleteCupom, addCupom, } from "../modules/cupons/redux/sagas/sagaCupom";
import { listPayments } from "../modules/assinatura/redux/sagas/sagaPayment";
import { updateAccount, deleteAccount, } from "../modules/account/redux/sagas/sagaAccount";
import { getInfor, getChart, getTop, getInforMedias, getInforProducts, } from "../modules/dashboard/redux/sagas/sagaDashboard";
import { getList, deleteCard, debug, dropi, updateExpire, getIntegrations, getIntegrationsNuvem, testIntegrations, addMonths, verifyWebhook, } from "../modules/admin/redux/sagas/sagaAdmin";
import { updateTemplate, getTemplate, sendEmailTeste, changeStatusDownload, } from "../modules/thema/redux/sagas/sagaTemplate";
import { listNotify } from "../components/Header/redux/sagas/sagaNotify";
import { listLanguage } from "../components/Header/redux/sagas/sagaLanguage";
import { updateDataProductMulti, listProducts, updateProduct, linkMediaProducts, deleteLinkMediaProducts, listVariants, addMedias, LinkMedias, urlMedias, updateDataProduct, searchPlatformAllProducts, } from "../modules/products/redux/sagas/sagaProduct";
import { uploadUrlNew, listMedia, listProductsByMedia, listMediaByProducts, updateMedia, downloadMedia, deleteMedias, updateMediaNew, uploadFile, } from "../modules/medias/redux/sagas/sagaProduct";
import { uploadMedia, deleteMediaById, uploadUrl, } from "../modules/medias/redux/sagas/sagaProduct";
import { listSettings, deleteUser, } from "../modules/settings/redux/sagas/sagaSettings";
import { getOrderData } from "../modules/yourmedias/redux/sagas/sagaYourMedias";
import { getSale, listSales, reSendEmail, reSendEmailMulti, reSendWhats, searchPlatform, searchPlatformAll, updateEmailSales } from "../modules/sales/redux/sagas/sagaSales";

export default function* rootSagas() {
  yield all([
    takeLatest("SAGA_LOGAR_LOGIN_ADMIN", logarAdmin),
    takeLatest("SAGA_LOGAR_LOGIN", logar),
    takeLatest("SAGA_LOGAR_PLUS_CONCLUIR", concluirLogarPlus),
    takeLatest("SAGA_REGISTER_LOGIN", register),
    takeLatest("SAGA_PASSWORD_LOGIN", password),

    takeLatest("SAGA_INTEGRATE_INTEGRATION", integrate),
    takeLatest("SAGA_GET_PLATFORMS_INTEGRATION", platforms),
    takeLatest("SAGA_DELETE_INTEGRATION", deleteIntegration),
    takeLatest("SAGA_GET_USER_ID", getUserId),
    takeLatest("SAGA_DELETE_NEW_INTEGRATION", deleteIntegrationNew),
    takeLatest("SAGA_INTEGRATE_NEW_INTEGRATION", integrateNew),

    takeLatest("SAGA_LIST_CUPONS", listCupons),
    takeLatest("SAGA_UPDATE_CUPOM", updateCupom),
    takeLatest("SAGA_DELETE_CUPOM", deleteCupom),
    takeLatest("SAGA_ADD_CUPOM", addCupom),

    takeLatest("SAGA_LIST_PAYMENTS", listPayments),

    takeLatest("SAGA_UPDATE_ACCOUNT", updateAccount),
    takeLatest("SAGA_DELETE_ACCOUNT", deleteAccount),

    takeLatest("SAGA_INFOR_DASHBOARD", getInfor),
    takeLatest("SAGA_INFOR_CHART", getChart),
    takeLatest("SAGA_INFOR_TOP", getTop),
    takeLatest("SAGA_INFOR_DASHBOARD_MEDIAS", getInforMedias),
    takeLatest("SAGA_INFOR_DASHBOARD_PRODUCTS", getInforProducts),

    takeLatest("SAGA_LIST_ADMIN", getList),
    takeLatest("SAGA_DELETE_CARD_ADMIN", deleteCard),
    takeLatest("SAGA_DEBUG_ADMIN", debug),
    takeLatest("SAGA_EXPIRE_ADMIN", updateExpire),
    takeLatest("SAGA_GET_INTEGRATIONS", getIntegrations),
    takeLatest("SAGA_GET_INTEGRATIONS_NUVEM", getIntegrationsNuvem),
    takeLatest("SAGA_TEST_INTEGRATIONS", testIntegrations),
    takeLatest("SAGA_DROPI_ADMIN", dropi),

    takeLatest("SAGA_VERIFY_WEBHOOK_CARD_ADMIN", verifyWebhook),

    takeLatest("SAGA_UPDATE_TEMPLATE", updateTemplate),

    takeLatest("SAGA_GET_TEMPLATES", getTemplate),

    takeLatest("SAGA_LIST_NOTIFY", listNotify),

    takeLatest("SAGA_LIST_SALES", listSales),
    takeLatest("SAGA_UPDATE_EMAIL_SALES", updateEmailSales),
    takeLatest("SAGA_GET_SALE", getSale),

    takeLatest("SAGA_RESEND_EMAIL", reSendEmail),
    takeLatest("SAGA_RESEND_WHATS", reSendWhats),
    takeLatest("SAGA_RESEND_MULTI_EMAIL", reSendEmailMulti),
    takeLatest("SAGA_SEARCH_PLATFORM", searchPlatform),
    takeLatest("SAGA_SEARCH_PLATFORM_ALL", searchPlatformAll),

    takeLatest("SAGA_SEARCH_PLATFORM_ALL_PRODUCTS", searchPlatformAllProducts),

    takeLatest("SAGA_CHANGE_STATUS_DOWNLOAD", changeStatusDownload),

    takeLatest("SAGA_LIST_LANGUAGE", listLanguage),

    takeLatest("GET_ORDER_DATA", getOrderData),

    takeLatest("SAGA_UPDATE_PRODUCT_MULTI", updateDataProductMulti),
    takeLatest("SAGA_LIST_PRODUCTS", listProducts),
    takeLatest("SAGA_LIST_MEDIA", listMedia),
    takeLatest("SAGA_UPDATE_MEDIA", updateMedia),
    takeLatest("PRODUCTS_BY_MEDIAS_REQUEST", listProductsByMedia),
    takeLatest("MEDIAS_BY_PRODUCTS_REQUEST", listMediaByProducts),
    takeLatest("LINK_MEDIA_PRODUCTS", linkMediaProducts),
    takeLatest("DELETE_LINK_MEDIA_PRODUCTS", deleteLinkMediaProducts),
    takeLatest("SAGA_LIST_VARIANTS", listVariants),
    takeLatest("SAGA_UPLOAD_MEDIA", uploadMedia),
    takeLatest("SAGA_UPLOAD_URL", uploadUrl),
    takeLatest("SAGA_DELETE_MEDIA", deleteMediaById),
    takeLatest("SAGA_UPDATE_PRODUCT", updateProduct),
    takeLatest("SAGA_LIST_SETTINGS", listSettings),
    takeLatest("SAGA_DELETE_USER", deleteUser),

    takeLatest("SAGA_UPLOAD_URL_NEW", uploadUrlNew),

    takeLatest("SAGA_UPLOAD_FILE", uploadFile),

    takeLatest("SAGA_PRODUCT_ADD_MEDIA", addMedias),
    takeLatest("SAGA_PRODUCT_LINK_MEDIA", LinkMedias),
    takeLatest("SAGA_PRODUCT_URL_MEDIA", urlMedias),

    takeLatest("SAGA_DELETE_MULTI_MEDIA", deleteMedias),
    takeLatest("SAGA_UPDATE_NEW_MEDIA", updateMediaNew),

    takeLatest("SAGA_EMAIL_TESTE_TEMPLATES", sendEmailTeste),

    takeLatest('SAGA_UPDATE_DATA_PRODUCT', updateDataProduct),

    takeLatest('SAGA_ADD_TIME_ADMIN', addMonths),

  ]);
}
