import React from 'react'
import { BarConfig, BarOptions, Container, Preview, } from './styled'


const LazyLoadingThema = () => {
    return (
        <Container>
          {/* <BarOptions /> */}
          <BarConfig />
          <Preview />
        </Container>
    )
}

export default LazyLoadingThema