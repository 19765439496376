import Page from "../../components/Page";
import InputCustom from "../../components/InputCustom";
import ErrorMessage from "../../components/ErrorInput";
import {
  BoxSocialsBtn,
  BoxFormLogin,
  BoxSocials,
  BoxSocialsTitle,
  BtnSocial,
  BoxSocialsInfor,
  BoxEmpreenderPlusLogin,
} from "./styled";
import { AiFillMail } from "react-icons/ai";
import { FaGoogle, FaFacebookF, FaLock } from "react-icons/fa";
import Btn from "../../components/Btn";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useEffect } from "react";
import { isVisibleScript } from "../../../../utils/displayScript";
import BtnEmpreenderPlus from "../../../../assets/imgs/empreender-plus.svg";
import { translations } from "../../../../utils/language";
import { getCookieFromString } from "../../../../utils/cookies";

const LoginAdmin = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const statuslogin = useSelector((state) => state.reducerLogin);
  const [id, setId] = useState(null);
  const language = useSelector((state) => state.reducerLanguage);

  const [enableLoginPlusBox, setEnableLoginPlusBox] = useState(false);
  const [plusUrl, setPlusUrl] = useState();

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Preencher o campo E-mail.")
        .email("Preencha um e-mail valido.")
        .max(200, "Máximo de 200 caracteres.")
        .min(3, "Minimo de 8 caracteres."),
      password: Yup.string()
        .required("Preencher o campo Senha.")
        .min(
          process.env.REACT_APP_HABILITAR_PLUS ? 3 : 8,
          `Minimo de ${process.env.REACT_APP_HABILITAR_PLUS ? 3 : 8
          } caracteres.`
        )
        .max(200, "Maximo de 200 caracteres."),
    }),
    onSubmit: (values) => {
      dispatch({ type: "SAGA_LOGAR_LOGIN_ADMIN", payload: { ...values, isAdmin: true } });
    },
  });

  useEffect(() => {
    if (
      !(
        statuslogin.loading === false &&
        statuslogin.error === false &&
        statuslogin.data.length === 0
      )
    ) {
      if (statuslogin.loading === true) {
        setId(
          toast.loading(translations["Aguarde..."][language.data], {
            toastId: 0,
          })
        );
      } else {
        if (statuslogin.error === true) {
          toast.update(id, {
            render:
              translations["Erro ao tentar efetuar login."][language.data],
            type: "error",
            isLoading: false,
            autoClose: 5000,
          });
        } else if (statuslogin.error === false) {
          toast.update(id, {
            render: translations["Login efetuado com sucesso."][language.data],
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
          formik.resetForm();
          if (statuslogin.data.data.user.isAdmin == 1) {
            history.push("/admin");
          } else {
            history.push("/");
          }
          //history.push("/admin")
          //
        }
      }
    }
  }, [statuslogin]);

  useEffect(() => {
    if (localStorage.getItem("depoimentos@login")) {
      history.push("/");
    }
  }, []);

  // useEffect(() => {
  //   localStorage.setItem("depoimentos@login", `
  //   {"user":{"id":914,"created_at":"2023-03-02T14:46:54.000-03:00","url_ask_depo":"fca94dc5","email":"cleivisonfla@hotmail.com","password":"$2b$10$8vtCflsV4MYrejGTi56imefV7LMUwL8pB9AxJYePlhPwHFVB3sKEO","name":"Cleivison Azevedo","whatsapp":"5534412421412","chave_empreender":"DJJ28b","current_plan":"1","gateway":null,"inter_user":null,"inter_pay":null,"cred_last_date":null,"coupon":null,"photo_url":"https://empreender.nyc3.digitaloceanspaces.com/default-profile.png","url_wall_videos":"63d28e9a","welcome_message":null,"thanks_message":null,"custom_url_thanks_message":null,"main_theme":null,"affiliate":null,"updated_at":"2023-03-02T14:46:54.000-03:00","active":1,"id_user_plus":"230732","id_loja_plus":"463648","pagou_plus":0,"cred_expire":0,"secondary_theme":null,"isAdmin":null},"integration":null,"integrationDropi":null,"access_token":{"type":"bearer","token":"MTIzMQ.kn9trfXZ63cTQxxkik50qA0t_JYaacG0YBm60bbwPeSJnJ6T2VHc5udPjOGw"}}
  //   `)
  // }, []);

  useEffect(() => {
    if (process.env.REACT_APP_HABILITAR_PLUS) {
      fetch(process.env.REACT_APP_EPLUS_ROTA_VERIFICAR_LOGIN, {
        credentials: "include",
      })
        .then((val) => val.text())
        .then((val) => {
          if (val === "logado") {
            setEnableLoginPlusBox(true);
          }
        })
        .catch((err) => console.log("erro plus"));
    }
  }, []);

  useEffect(() => {
    let linkToLoginWithPlus = "";
    const country = localStorage.getItem("country");
    if (country === "BR") {
      linkToLoginWithPlus =
        "https://app.empreender.plus/selecionar-app?app=DIJITAL";
    } else if (country === "MX") {
      linkToLoginWithPlus =
        "https://mx.empreender.plus/selecionar-app?app=DIJITAL";
    } else if (country === "AR") {
      linkToLoginWithPlus =
        "https://ar.empreender.plus/selecionar-app?app=DIJITAL";
    } else {
      linkToLoginWithPlus =
        "https://app.empreender.plus/selecionar-app?app=DIJITAL";
    }

    let affiliate = localStorage.getItem("depoimentos@affiliate");

    if (affiliate != null) linkToLoginWithPlus += `&ref=${affiliate}`;

    let utm_source = getCookieFromString("utm_source");
    linkToLoginWithPlus += `&utm_source=${utm_source}`;

    let utm_campaign = getCookieFromString("utm_campaign");
    linkToLoginWithPlus += `&utm_campaign=${utm_campaign}`;

    let utm_medium = getCookieFromString("utm_medium");
    linkToLoginWithPlus += `&utm_medium=${utm_medium}`;

    setPlusUrl(linkToLoginWithPlus);
  }, [language]);

  if (enableLoginPlusBox) {
    return (
      <Page admin={true}>
        <BoxEmpreenderPlusLogin>
          <a href={plusUrl} className="btn-logar-plus">
            <img
              alt="Empreender Plus"
              style={{ marginRight: "8px" }}
              width="24"
              src="https://empreender.nyc3.digitaloceanspaces.com/landingpage/landingpage/ap8n31063713.svg"
            />
            <span>Acessar pelo Plus</span>
          </a>
          <BoxSocialsTitle>
            <span />
            <label>{translations["ou acesse com"][language.data]}</label>
            <span />
          </BoxSocialsTitle>
          <span>
            <strong
              onClick={() => {
                setEnableLoginPlusBox(false);
              }}
            >
              E-mail e {translations["Senha"][language.data]}
            </strong>
          </span>
        </BoxEmpreenderPlusLogin>
      </Page>
    );
  }

  return (
    <Page admin={true}>
      <BoxFormLogin onSubmit={formik.handleSubmit}>
        <InputCustom>
          <span>
            <AiFillMail size={20} color={"gray"} />
          </span>
          <input
            name={"email"}
            type={"text"}
            placeholder={"E-mail"}
            value={formik.values.email}
            onChange={(e) => {
              e.target.value = e.target.value.trim();
              formik.handleChange(e);
            }}
          />
        </InputCustom>
        {formik.errors.email && formik.touched.email && (
          <ErrorMessage>{formik.errors.email}</ErrorMessage>
        )}

        <InputCustom>
          <span>
            <FaLock size={20} color={"gray"} />
          </span>
          <input
            name={"password"}
            type={"password"}
            placeholder={"Senha"}
            value={formik.values.password}
            onChange={formik.handleChange}
          />
        </InputCustom>
        {formik.errors.password && formik.touched.password && (
          <ErrorMessage>{formik.errors.password}</ErrorMessage>
        )}

        <span>
          <Btn
            label={"Entrar"}
            onClick={() => {
              if (formik.dirty === false)
                toast.warning("Preencha os dados.", { toastId: 0 });
            }}
          />
          {/* <label
            onClick={() => {
              history.push("/senha");
            }}
          >
            {translations["Esqueceu sua senha?"][language.data]}
          </label> */}
        </span>
      </BoxFormLogin>
      {/* <BoxSocials>
        <BoxSocialsTitle>
          <span />
          <label>{translations["ou acesse com"][language.data]}</label>
          <span />
        </BoxSocialsTitle>
        <BoxSocialsBtn>
          <BtnSocial
            cursor={
              process.env.REACT_APP_HABILITAR_PLUS ? "pointer" : "not-allowed"
            }
            background={
              process.env.REACT_APP_HABILITAR_PLUS ? "#3b589e" : "gray"
            }
            onClick={() => {
              if (process.env.REACT_APP_HABILITAR_PLUS) {
                let affiliate = localStorage.getItem("depoimentos@affiliate");
                let utm_source = getCookieFromString("utm_source");
                let utm_campaign = getCookieFromString("utm_campaign");
                let utm_medium = getCookieFromString("utm_medium");

                let linkToRedirect =
                  process.env.REACT_APP_API_URL +
                  `/eplus/auth/login-social/facebook?utm_source=${utm_source}&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}`;
                if (affiliate) linkToRedirect += `&affiliate=${affiliate}`;

                window.location.href = linkToRedirect;
              }
            }}
          >
            <span>
              <FaFacebookF size={15} color={"white"} />
            </span>
            <label>Facebook</label>
          </BtnSocial>
          <BtnSocial
            cursor={
              process.env.REACT_APP_HABILITAR_PLUS ? "pointer" : "not-allowed"
            }
            background={
              process.env.REACT_APP_HABILITAR_PLUS ? "#db4437" : "gray"
            }
            onClick={() => {
              if (process.env.REACT_APP_HABILITAR_PLUS) {
                let affiliate = localStorage.getItem("depoimentos@affiliate");

                let utm_source = getCookieFromString("utm_source");
                let utm_campaign = getCookieFromString("utm_campaign");
                let utm_medium = getCookieFromString("utm_medium");

                let linkToRedirect =
                  process.env.REACT_APP_API_URL +
                  `/eplus/auth/login-social/google?utm_source=${utm_source}&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}`;
                if (affiliate) linkToRedirect += `&affiliate=${affiliate}`;

                window.location.href = linkToRedirect;
              }
            }}
          >
            <span>
              <FaGoogle size={15} color={"white"} />
            </span>
            <label>Google</label>
          </BtnSocial>
          <a href={plusUrl}>
            <img
              style={{ border: "1px solid #202020", borderRadius: "50px" }}
              height="35"
              src={BtnEmpreenderPlus}
            />
          </a>
        </BoxSocialsBtn>
        <BoxSocialsInfor>
          <label>
            {translations["Não possui uma conta?"][language.data]}{" "}
            <strong
              onClick={() => {
                history.push("/cadastro");
              }}
            >
              {translations["Clique no ícone"][language.data]}
            </strong>
          </label>
        </BoxSocialsInfor>
      </BoxSocials> */}
      {isVisibleScript(false)}
    </Page>
  );
};

export default LoginAdmin;
