import { AcademicCapIcon, CreditCardIcon, FolderArrowDownIcon, HomeIcon, PaintBrushIcon, ShoppingBagIcon, ShoppingCartIcon, Square3Stack3DIcon, UserIcon } from "@heroicons/react/24/outline";
import Sidebar from "../../../empreenderUI/SideBar";
import ItemIcon from "../../../empreenderUI/ui/ItemIcon";
import ItemMenu from "../../../empreenderUI/ui/ItemMenu";
import ItemText from "../../../empreenderUI/ui/ItemText";
import Separator from "../../../empreenderUI/ui/Separator";
import ItemSub from "../../../empreenderUI/ItemSub";
import { useHistory } from "react-router-dom";
import ContentApp from "../../../empreenderUI/ContentApp";
import PerfilMenu from "../../../empreenderUI/ui/PerfilMenu";
import { Button } from "../../../empreenderUI/ui/Button";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";

const LayoutUI = (props) => {

    const history = useHistory()
    const dispatch = useDispatch()

    let token = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.token;
    let tokenType = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.type;

    useEffect(() => {
        const storageLanguage = localStorage.getItem("language");
        if (storageLanguage) {
            dispatch({ type: "SAGA_LIST_LANGUAGE", payload: { language: storageLanguage, }, });
        } else {
            dispatch({ type: "SAGA_LIST_LANGUAGE", payload: { language: "pt", }, });
        }
    }, [localStorage, dispatch]);

    const [isOpenMenu, setIsOpenMenu] = useState(localStorage.getItem("isOpenMenu") == null || localStorage.getItem("isOpenMenu") == undefined ? true : localStorage.getItem("isOpenMenu"))

    useEffect(() => {
        const menu = document.getElementById("sidebar_trigger");
        if (menu) { menu.addEventListener("click", (handleClick)) }
        return () => { if (menu) { menu.removeEventListener("click", handleClick); } };
    }, []);

    // Função que será chamada no clique
    const handleClick = (event) => {
        if (localStorage.getItem("isOpenMenu") == "true") {
            localStorage.setItem("isOpenMenu", "false")
            setIsOpenMenu(localStorage.getItem("isOpenMenu"))
        } else {
            localStorage.setItem("isOpenMenu", "true")
            setIsOpenMenu(localStorage.getItem("isOpenMenu"))
        }
    };

    // const copy = () => {
    //     navigator.clipboard.writeText(JSON.parse(localStorage.getItem("depoimentos@login")).user.chave_empreender);
    //     toast.success("Chave empreender copiada.");
    //     //toast.success(translations["Chave empreender copiada."][language]);
    // };

    return (
        <Sidebar
            appEndPoint={process.env.REACT_APP_API_URL.replace(/^https?:\/\//, "")}
            headers={{ "Content-Type": "application/json", Authorization: tokenType + " " + token, }}
            app={"Dijital"}
            isOpen={isOpenMenu == "false" ? false : true}
            chave_empreender={JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.chave_empreender}
        //isOpen={true}
        >

            <ItemMenu
                asChild
                //onClick={() => { history.push("/") }}
                isActive={window.location.pathname == "/" ? true : false}>
                <Link to={"/"}>
                    <ItemIcon>
                        <HomeIcon />
                    </ItemIcon>
                    <ItemText>Dashboard</ItemText>
                </Link>

            </ItemMenu>

            <ItemMenu
                asChild
                //onClick={() => { history.push("/produtos") }}
                isActive={window.location.pathname == "/produtos" || window.location.pathname.includes("/variacoes") ? true : false}>
                <Link to={"/produtos"}>
                    <ItemIcon>
                        <ShoppingBagIcon />
                    </ItemIcon>
                    <ItemText>Produtos</ItemText>
                </Link>

            </ItemMenu>

            <ItemMenu
                onClick={() => { history.push("/galeria") }}
                isActive={window.location.pathname == "/galeria" ? true : false}>
                <ItemIcon>
                    <FolderArrowDownIcon />
                </ItemIcon>
                <ItemText>Galeria</ItemText>
            </ItemMenu>

            <ItemMenu
                onClick={() => { history.push("/pedidos") }}
                isActive={window.location.pathname == "/pedidos" || window.location.pathname.replace(/\d+/g, "").includes("pedidos//produtos") ? true : false}>
                <ItemIcon>
                    <ShoppingCartIcon />
                </ItemIcon>
                <ItemText>Pedidos</ItemText>
            </ItemMenu>

            <ItemMenu
                onClick={() => { history.push("/passo-a-passo") }}
                isActive={window.location.pathname == "/passo-a-passo" ? true : false}>
                <ItemIcon>
                    <AcademicCapIcon />
                </ItemIcon>
                <ItemText>Passo a Passo</ItemText>
            </ItemMenu>

            {/* <ItemMenu
                onClick={() => { history.push("/ajuda") }}
                isActive={window.location.pathname == "/ajuda" ? true : false}>
                <ItemIcon>
                    <UserIcon />
                </ItemIcon>
                <ItemText>Ajuda</ItemText>
            </ItemMenu> */}

            <Separator>Configurações</Separator>

            <ItemMenu
                //onClick={() => { history.push("/personalizar") }}
                isActive={window.location.pathname.includes("/personalizar") ? true : false}>
                <ItemIcon>
                    <PaintBrushIcon />
                </ItemIcon>
                <ItemText>Personalizar</ItemText>

                <ItemSub onClick={() => { history.push("/personalizar/checkout") }}>Checkout</ItemSub>
                <ItemSub onClick={() => { history.push("/personalizar/page-checkout") }}>Página de Downloads</ItemSub>
                <ItemSub onClick={() => { history.push("/personalizar/email") }}>E-mail</ItemSub>
            </ItemMenu>

            <ItemMenu
                onClick={() => { history.push("/integracoes") }}
                isActive={window.location.pathname == "/integracoes" ? true : false}>
                <ItemIcon>
                    <Square3Stack3DIcon />
                </ItemIcon>
                <ItemText>Integrações</ItemText>
            </ItemMenu>

            <ItemMenu
                onClick={() => { history.push("/pagamentos") }}
                isActive={window.location.pathname == "/pagamentos" ? true : false}>
                <ItemIcon>
                    <CreditCardIcon />
                </ItemIcon>
                <ItemText>Pagamentos</ItemText>
            </ItemMenu>

            <PerfilMenu>
                {/* <Button
                    onClick={() => {
                        navigator.clipboard.writeText(JSON.parse(localStorage.getItem("depoimentos@login")).user.chave_empreender);
                        toast.success("Chave empreender copiada.");
                    }}
                    className="text-gray-800 text-xs font-normal">
                    CHAVE DIGITAL {JSON.parse(localStorage.getItem("depoimentos@login")).user.chave_empreender}
                </Button> */}
                <Button
                    onClick={() => {
                        try {
                            localStorage.removeItem("depoimentos@login");
                            window.location.href = 'https://app.dijital.com.br/login'
                        } catch (error) { }
                    }}
                    className="text-gray-800 text-xs font-normal">
                    Sair
                </Button>
            </PerfilMenu>

            <ContentApp>
                <div style={{ padding: "20px 0px 60px 0px" }}>
                    {props.children}
                </div>
            </ContentApp> 

        </Sidebar>
    )
}

export default LayoutUI