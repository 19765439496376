import App365Icon from '../ui/appsIcons/365';
import App365IconBranco from '../ui/appsIcons/365Branco';
import AutoMagicoIcon from '../ui/appsIcons/automagico';
import AutoMagicoIconBranco from '../ui/appsIcons/automagicoBranco';
import Boss from '../ui/appsIcons/Boss';
import BotaoZapIcon from '../ui/appsIcons/BotaoZap';
import BotaoZapIconBranco from '../ui/appsIcons/BotaoZapBranco';
import BrindeNoCarrinhoIcon from '../ui/appsIcons/BrindeNoCarrinhoIcon';
import BrindeNoCarrinhoIconBranco from '../ui/appsIcons/BrindeNoCarrinhoIconBranco';
import CampoPersonalizadoIcon from '../ui/appsIcons/CampoPersonalizadoIcon';
import CampoPersonalizadoIconBranco from '../ui/appsIcons/CampoPersonalizadoIconBranco';
import ChegueiIcon from '../ui/appsIcons/ChegueiIcon';
import ChegueiIconBranco from '../ui/appsIcons/ChegueiIconBranco';
import CompreJuntoIcon from '../ui/appsIcons/CompreJunto';
import CompreJuntoIconBranco from '../ui/appsIcons/CompreJuntoBranco';
import DijitalIcon from '../ui/appsIcons/Dijital';
import DijitalIconBranco from '../ui/appsIcons/DijitalBranco';
import DropDigitalIcon from '../ui/appsIcons/DropDigitalIcon';
import DropDigitalIconBranco from '../ui/appsIcons/DropDigitalIconBranco';
import DropiIcon from '../ui/appsIcons/DropiIcon';
import DropiIconBranco from '../ui/appsIcons/DropiIconBranco';
import FreteHero from '../ui/appsIcons/FreteHero';
import FreteHeroBranco from '../ui/appsIcons/FreteHeroBranco';
import LilyIcon from '../ui/appsIcons/LilyIcon';
import LilyIconBranco from '../ui/appsIcons/LilyIconBranco';
import MelhorApp from '../ui/appsIcons/MelhorApp';
import MelhorAppBranco from '../ui/appsIcons/MelhorAppBranco';
import OvniIcon from '../ui/appsIcons/OvniIcon';
import OvniIconBranco from '../ui/appsIcons/OvniIconBranco';
import ParcelamentoIcon from '../ui/appsIcons/ParcelamentoIcon';
import ParcelamentoIconBranco from '../ui/appsIcons/ParcelamentoIconBranco';
import PromocaoAgendadaIcon from '../ui/appsIcons/PromocaoAgendadaIcon';
import PromocaoAgendadaIconBranco from '../ui/appsIcons/PromocaoAgendadaIconBranco';
import RastreioIcon from '../ui/appsIcons/RastreioIcon';
import RastreioIconBranco from '../ui/appsIcons/RastreioIconBranco';
import ReplayIcon from '../ui/appsIcons/ReplayIcon';
import ReplayIconBranco from '../ui/appsIcons/ReplayIconBranco';
import SakIcon from '../ui/appsIcons/SakIcon';
import SakIconBranco from '../ui/appsIcons/SakIconBranco';
import SuperCheckoutIcon from '../ui/appsIcons/SuperCheckout';
import SuperCheckoutIconBranco from '../ui/appsIcons/SuperCheckoutBranco';
import SuperFavoritoIcon from '../ui/appsIcons/SuperFavoritoIcon';
import SuperFavoritoIconBranco from '../ui/appsIcons/SuperFavoritoIconBranco';
import WidgetInstagramIcon from '../ui/appsIcons/WidgetInstagramIcon';
import WidgetInstagramIconBranco from '../ui/appsIcons/WidgetInstagramIconBranco';
import WidgetTikTokIcon from '../ui/appsIcons/WidgetTikTokIcon';
import WidgetTikTokIconBranco from '../ui/appsIcons/WidgetTikTokIconBranco';

export const EmpreenderApps = [
	{
		name: '365 Artes',
		eplus: '365ARTES',
		icon: <App365Icon />,
		iconwhite: <App365IconBranco />,
		tags: ['365artes', '365 artes', '365ARTES'],
		link: 'https://365artes.com.br',
		content: 'Um banco de artes para sua loja editáveis no Canva. Adicione selos nas fotos dos produtos',
		slug: '365artes',
	},
	{
		name: 'Automágico',
		eplus: 'AUTOMAGICO',
		icon: <AutoMagicoIcon />,
		iconwhite: <AutoMagicoIconBranco />,
		tags: ['automagico', 'auto mágico', 'AUTOMAGICO'],
		link: 'https://automagico.com.br',
		content: 'Crie templates e cadastre produtos em massa a partir das fotos na sua loja',
		slug: 'automagico',
	},
	{
		name: 'Brinde no Carrinho',
		eplus: 'BRINDE',
		icon: <BrindeNoCarrinhoIcon />,
		iconwhite: <BrindeNoCarrinhoIconBranco />,
		tags: ['brinde no carrinho', 'brinde', 'BRINDE'],
		link: 'https://brindenocarrinho.com.br',
		content: 'Aumente seu ticket médio e melhore a experiência do seu cliente oferecendo brindes',
		slug: 'brindenocarrinho',
	},
	{
		name: 'Frete Hero',
		eplus: 'FRETEHERO',
		icon: <FreteHero />,
		iconwhite: <FreteHeroBranco />,
		tags: ['frete hero', 'frete', 'FRETEHERO'],
		link: 'https://fretehero.com.br',
		content: 'Defina um prazo adicional de frete para produtos personalizados, sob encomenda e multi-CD',
		slug: 'fretehero',
	},
	{
		name: 'Botão Zap',
		eplus: 'zap',
		icon: <BotaoZapIcon />,
		iconwhite: <BotaoZapIconBranco />,
		tags: ['botão zap', 'botaozap', 'zap'],
		link: 'https://empreender.plus/',
		content: 'Inicie o suporte com uma mensagem padrão que carrega o link do produto que seu cliente está interessado',
		slug: 'botaozap',
	},
	{
		name: 'Campo Personalizado',
		eplus: 'PERSONALIZADO',
		icon: <CampoPersonalizadoIcon />,
		iconwhite: <CampoPersonalizadoIconBranco />,
		tags: ['campo personalizado', 'campo', 'PERSONALIZADO'],
		link: 'https://campopersonalizado.com.br',
		content: 'Adicione campos extras aos seus produtos, como texto, arquivo ou seleção para produtos personalizados',
		slug: 'campopersonalizado',
	},
	{
		name: 'Cheguei',
		eplus: 'CHEGUEI',
		icon: <ChegueiIcon />,
		iconwhite: <ChegueiIconBranco />,
		tags: ['cheguei', 'CHEGUEI'],
		link: 'https://cheguei.com.br',
		content: 'Salve clientes interessados em produtos esgotados e os avise quando essas mercadorias voltarem',
		slug: 'cheguei',
	},
	{
		name: 'Compre Junto',
		eplus: 'compre junto',
		icon: <CompreJuntoIcon />,
		iconwhite: <CompreJuntoIconBranco />,
		tags: ['compre junto'],
		link: 'https://empreender.plus',
		content: 'Potencialize seu ticket médio com vendas de kits de produtos em uma unica transação',
		slug: 'comprejunto',
	},
	{
		name: 'Dijital',
		eplus: 'DIJITAL',
		icon: <DijitalIcon />,
		iconwhite: <DijitalIconBranco />,
		tags: ['dijital', 'digital', 'DIJITAL'],
		link: 'https://dijital.com.br',
		content: 'Venda e-books, PDFs, MP3, MP4 e todo tipo de infoproduto na sua loja virtual',
		slug: 'dijital',
	},
	{
		name: 'Dropi',
		eplus: 'DROPI',
		icon: <DropiIcon />,
		iconwhite: <DropiIconBranco />,
		tags: ['dropi', 'DROPI'],
		link: 'https://dropi.com.br',
		content: 'Venda produtos do mundo todo sem estoque com dropshipping internacional e nacional',
		slug: 'dropi',
	},
	{
		name: 'Drop.Digital',
		eplus: 'DROPDIGITAL',
		icon: <DropDigitalIcon />,
		iconwhite: <DropDigitalIconBranco />,
		tags: ['drop.digital', 'dropdigital', 'drop digital', 'DROPDIGITAL'],
		link: 'https://dropdigital.com.br',
		content: 'Importe infoprodutos como cursos e ebooks do seu nicho e venda sem estoque na sua loja',
		slug: 'dropdigital',
	},
	{
		name: 'Empreender Plus',
		eplus: 'PL',
		icon: <Boss />,
		iconwhite: <Boss />,
		tags: ['empreender plus', 'EMPREENDERPLUS'],
		link: 'https://empreender.plus/',
		content: 'Importe infoprodutos como cursos e ebooks do seu nicho e venda sem estoque na sua loja',
		slug: 'plus',
	},
	{
		name: 'Landing Page',
		eplus: 'LANDINGPAGE',
		icon: <MelhorApp />,
		iconwhite: <MelhorAppBranco />,
		tags: ['landingpage', 'landing page', 'LANDINGPAGE'],
		link: 'https://landingpage.com.br/',
		content: 'Construa páginas web sem precisar de código. Escolha um modelo pronto ou comece do zero',
		slug: 'landingpage',
	},
	{
		name: 'Lily Review',
		eplus: 'LILY',
		icon: <LilyIcon />,
		iconwhite: <LilyIconBranco />,
		tags: ['lily review', 'lily', 'lily reviews', 'LILY'],
		link: 'https://lily.com.br',
		content: 'Adicione avaliações em texto, foto ou vídeo, além de perguntas e respostas nos seus produtos',
		slug: 'lilyreviews',
	},
	{
		name: 'Ovni',
		eplus: 'OVNI',
		icon: <OvniIcon />,
		iconwhite: <OvniIconBranco />,
		tags: ['ovni', 'OVNI'],
		link: 'https://ovni.com.br/',
		content: 'Crie campanhas e encontre o influenciador perfeito para seus produtos na rede social que preferir',
		slug: 'ovni',
	},
	{
		name: 'Parcelamento',
		eplus: 'PT',
		icon: <ParcelamentoIcon />,
		iconwhite: <ParcelamentoIconBranco />,
		tags: ['parcelamento', 'PARCELAMENTO'],
		link: 'https://parcelamento.com/',
		content: 'Crie campanhas e encontre o influenciador perfeito para seus produtos na rede social que preferir',
		slug: 'parcelamento',
	},
	{
		name: 'Promoção agendada',
		eplus: 'PROMOAGENDADA',
		icon: <PromocaoAgendadaIcon />,
		iconwhite: <PromocaoAgendadaIconBranco />,
		tags: ['promoção agendada', 'promocao', 'PROMOAGENDADA'],
		link: 'https://empreender.plus',
		content: 'Programe alterações de preço em massa e aumente suas vendas em datas comemorativas',
		slug: 'promocaoagendada',
	},
	{
		name: 'Rastreio.net',
		eplus: 'RASTREIO',
		icon: <RastreioIcon />,
		iconwhite: <RastreioIconBranco />,
		tags: ['RASTREIO', 'rastreio'],
		link: 'https://get.rastreio.net',
		content: 'Adicione um vídeo flutuante à sua loja e mostre seus produtos na prática, oferecendo uma experiência visual envolvente',
		slug: 'rastreio',
	},
	{
		name: 'Replay',
		eplus: 'repeticao',
		icon: <ReplayIcon />,
		iconwhite: <ReplayIconBranco />,
		tags: ['replay', 'repeticao'],
		link: 'https://empreender.plus/',
		content: 'Adicione um vídeo flutuante à sua loja e mostre seus produtos na prática, oferecendo uma experiência visual envolvente',
		slug: 'replay',
	},
	{
		name: 'Sak',
		eplus: 'W',
		icon: <SakIcon />,
		iconwhite: <SakIconBranco />,
		tags: ['sak', 'suporte', 'atendimento', 'W'],
		link: 'https://get.sak.com.br',
		content: 'Recupere carrinhos, boletos e PIX com automações de WhatsApp. Crie artigos FAQs e atenda com IA',
		slug: 'suporte',
	},
	{
		name: 'Super Checkout',
		eplus: 'SUPERNUVEM',
		icon: <SuperCheckoutIcon />,
		iconwhite: <SuperCheckoutIconBranco />,
		tags: ['super checkout', 'checkout', 'SuperCheckout', 'SUPERNUVEM'],
		link: 'https://www.supercheckout.com.br/',
		content: 'Aumente a conversão com timer, recomendações de produtos, selos de autoridade e upsell no checkout.',
		slug: 'supercheckout',
	},
	{
		name: 'Super Favoritos',
		eplus: 'SUPERFAV',
		icon: <SuperFavoritoIcon />,
		iconwhite: <SuperFavoritoIconBranco />,
		tags: ['super favorito', 'favorito', 'SUPERFAV'],
		link: 'https://empreender.plus/',
		content: 'Aumente suas vendas com lista de desejos e histórico de navegação na sua loja',
		slug: 'superfavoritos',
	},
	{
		name: 'Widget Instagram',
		eplus: 'WIDGETINSTA',
		icon: <WidgetInstagramIcon />,
		iconwhite: <WidgetInstagramIconBranco />,
		tags: ['widget instagram', 'instagram', 'social', 'WIDGETINSTA'],
		link: 'https://widget.com.br',
		content: 'Adicione o feed do Instagram na sua loja. Marque produtos e leve o cliente para o checkout',
		slug: 'widget',
	},
	{
		name: 'Widget TikTok',
		eplus: 'WIDGETTIK',
		icon: <WidgetTikTokIcon />,
		iconwhite: <WidgetTikTokIconBranco />,
		tags: ['widget tiktok', 'tiktok', 'social', 'WIDGETTIK'],
		link: 'https://widget.com.br',
		content: 'Adicione vídeos do TikTok na sua loja. Marque produtos e leve o cliente para o checkout',
		slug: 'widget',
	},
];
