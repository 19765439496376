import React, { ReactNode } from 'react';
import Modal from './Modal/Modal';
import ModalTrigger from './Modal/ModalTrigger';
import ModalContent from './Modal/ModalContent';
import AppModal, { AppProps } from './AppModal';
import IntegracaoBox from './IntegracoesBox';
import { Button } from './Button';

interface previewProps {
	eplusApps: {
		[key: string]: {
			integrations?: Integration;
		};
	};
	app: {
		icon: ReactNode;
		name: ReactNode;
		content: ReactNode;
		eplus: string;
		integrations?: Integration;
		link?: string;
	};
}

interface iconProps {
	width: number;
	height: number;
}

interface Integration {
	[key: string]: {
		show: boolean;
		img: string;
	};
}

type AppPreviewProps = previewProps & AppProps;

const AppPreview: React.FC<AppPreviewProps> = ({ eplusApps, app }) => {
	return (
		<ul className="w-[300px] !p-5 h-full bg-white">
			<div className="w-full flex flex-col gap-4 h-full justify-between">
				<div className="flex flex-row items-center gap-2">
					<div className="size-12 bg-gray-100 rounded-lg flex items-center justify-center">
						<span>{React.cloneElement(app.icon as React.ReactElement<iconProps>, { width: 35, height: 35 })}</span>
					</div>
					<span className="text-gray-900 font-semibold break-normal whitespace-nowrap font-base">{app.name}</span>
				</div>
				<p className="text-gray-900 text-sm">{app.content}</p>

				<div className="flex flex-row gap-1.5">
					{eplusApps &&
						eplusApps[app.eplus]?.integrations &&
						Object.entries(eplusApps[app.eplus]?.integrations || {})
							.filter(([, value]) => value.show)
							.map(([plataforma, details], index) => (
								<React.Fragment key={index}>
									<IntegracaoBox plataforma={plataforma} index={index} image={details.img} />
								</React.Fragment>
							))}
				</div>

				<div className="w-full flex flex-row justify-end gap-2">
					<Button asChild variant="outline">
						<a href={`${app.link}`} target="_blank" rel="noopener noreferrer">
							Saiba mais
						</a>
					</Button>
					{Object.keys(eplusApps).length > 0 ? (
						<Modal>
							<ModalTrigger>
								<Button variant="outline">Entrar</Button>
							</ModalTrigger>
							<ModalContent>
								<AppModal app={app} />
							</ModalContent>
						</Modal>
					) : (
						<Button asChild variant="outline">
							<a href={`https://app.empreender.plus/?app=${app.eplus}`} target="_blank" rel="noopener noreferrer">
								Entrar
							</a>
						</Button>
					)}
				</div>
			</div>
		</ul>
	);
};

export default AppPreview;
