import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Header from "../../../../components/Header";
import Contents from "../../../../components/Contents";
import Card from "../../components/Card/index";
import { Container, ContainerCards } from "./styled";
import { isVisibleScript } from "../../../../utils/displayScript";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { translations } from "../../../../utils/language";
import HelpClick from "../../../../components/HelpClick";
import ModalInforVideo from "../../../../components/modal-infor-video";
import LayoutNew from "../../../../components/tailwind/layout-new";
import { ContainerNew } from "../../../dashboard/pages/home-new/styled";
import LayoutUI from "../../../../components/tailwind/layout-ui";

const Home = () => {
  // useEffect(() => {
  //     const script2 = document.createElement("script")
  //     script2.type = "text/javascript"
  //     script2.src = "https://empreender.nyc3.digitaloceanspaces.com/wcr/user_wfaqjs/619678452e2db.js";
  //     script2.defer = "defer"
  //     document.body.appendChild(script2);
  // }, [])

  const [query, setQuery] = useState("");
  const queryHelp = useSelector((state) => state.reducerQueryHelp);
  const dispatch = useDispatch();
  const language = useSelector((state) => state.reducerLanguage);
  const [visibleModalInfor, setVisibleModalInfor] = useState(false)

  useEffect(() => {
    setQuery(queryHelp);
    dispatch({ type: "SET_QUERY_HELP", payload: "" });

  }, []);

  return (
    <>
      <ContainerNew>



        <ModalInforVideo visible={visibleModalInfor} setVisibleModal={setVisibleModalInfor} video={`https://www.youtube.com/embed/${language.data == "es" ? "UlDDrPMfJy0" : "UlDDrPMfJy0"}`} />

        <Container>
          <iframe
            scrolling="auto"
            allow="clipboard-write"
            src={language.data === "es" ? `https://dijital-latam.sak.com.br/faq` : `https://dijital.sak.com.br/faq`}
            title="Dijital-faq"
            style={{ border: "none", width: "100%", height: "120vh" }}
            frameBorder={0}
          ></iframe>
          <ContainerCards>
            <Card
              background="#20D489"
              color="white"
              headerText={translations["ajuda-atendimento"][language.data]}
              mainText={translations["ajuda-suporte-whats"][language.data]}
              btnText={translations["ajuda-ir-whats"][language.data]}
              btnAction="javascript:sak_toggle_widget()"
            />
            <Card
              background="#005AFF"
              color="#ffffff"
              headerText={translations["ajuda-canais"][language.data]}
              mainText={translations["ajuda-suporte-forum"][language.data]}
              btnText={translations["ajuda-ir-forum"][language.data]}
              btnAction="https://forum.empreender.com.br/t/geral"
            />
          </ContainerCards>
        </Container>

        {
          language.data == "es" &&
          <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
            <div onClick={() => { setVisibleModalInfor(true) }}>
              <HelpClick text={translations["alert-ajuda"][language.data]} />
            </div>
          </div>
        }

        {isVisibleScript(true)}
      </ContainerNew>
    </>
  );
};

export default Home;
