import React from "react";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";

import PageLogin from "../modules/login/pages/home";
import LoginPlus from "../modules/login/pages/LoginPlus";
import PageRegister from "../modules/login/pages/register";
import PagePassword from "../modules/login/pages/password";

import PageDashboard from "../modules/dashboard/pages/home";
import PageDashboardNew from "../modules/dashboard/pages/home-new";

import PageIntegrations from "../modules/integrations/pages/home";
import PageIntegrationsNew from "../modules/integrations/pages/home-new";

import PageCode from "../modules/integrations/pages/code";
import PageCupons from "../modules/cupons/pages/home";

import PageAssinatura from "../modules/assinatura/pages/home";
import PageAssinaturaNew from "../modules/assinatura/pages/home-new";

import PageAjuda from "../modules/ajuda/pages/home";
import InjectAxiosInterceptors from "../services/InjectAxiosInterceptors";
import PageAccount from "../modules/account/pages/home";

import PageAdmin from "../modules/admin/pages/home";
import PageAdminNew from "../modules/admin/pages/home-new";

import PageThema from "../modules/thema/pages/home";
import PageThemaNew from "../modules/thema/pages/home-new";

import PageAffiliate from "../modules/login/pages/affiliate";

import PageProducts from "../modules/products/pages/home";
import PageProductsNew from "../modules/products/pages/home-new";
import PageProductsNewMidias from "../modules/products/pages/medias";
import PageProductsNewVariants from "../modules/products/pages/variants";

import PageMedias from "../modules/medias/pages/home";
import PageMediasNew from "../modules/medias/pages/home-new";
import PageMediasNewProducts from "../modules/medias/pages/products";

import PageSettings from "../modules/settings/pages";
import PageSteps from "../modules/steps/pages/home";

import PageYourMedias from "../modules/yourmedias/pages";
import PageYourMediasNew from "../modules/yourmedias/pages-new/home";
import PageYourMediasNewTable from "../modules/yourmedias/pages-new/home-table";

import PageSales from "../modules/sales/pages/home";
import PageSalesNew from "../modules/sales/pages/home-new";
import PageSalesNewProducts from "../modules/sales/pages/products";
import LayoutUI from "../components/tailwind/layout-ui";
import LoginAdmin from "../modules/login/pages/login-admin";

const Routes = () => {
  return (
    <BrowserRouter>
      <InjectAxiosInterceptors />
      <Switch>
        <Route exact path="/eplus/auth/link-magico">
          <LoginPlus />
        </Route>

        <Route exact path="/dashboard">
          <PageDashboardNew />
        </Route>

        <Route exact path="/login">
          <PageLogin />
        </Route>

        <Route exact path="/login/admin">
          <LoginAdmin />
        </Route>

        <Route exact path="/cadastro">
          <PageRegister />
        </Route>

        <Route exact path="/senha">
          <PagePassword />
        </Route>

        <Route exact path="/cadastro/afiliado">
          <PageAffiliate />
        </Route>

        <PrivateRoute exact path="/admin">
          <PageAdminNew />
        </PrivateRoute>

        <Route exact path="/suamidia/:user_id/:store_id/:order_id">
          <PageYourMediasNewTable />
        </Route>

        <PrivateRoute exact path="/integracoes/:type">
          <PageCode />
        </PrivateRoute>

        <Route>
          <Switch>
            <LayoutUI>
              <PrivateRoute exact path="/">
                <PageDashboardNew />
              </PrivateRoute>

              <PrivateRoute exact path="/cupons">
                <PageCupons />
              </PrivateRoute>

              <PrivateRoute exact path="/pagamentos">
                <PageAssinaturaNew />
              </PrivateRoute>

              <PrivateRoute exact path="/ajuda">
                <PageAjuda />
              </PrivateRoute>

              <PrivateRoute exact path="/conta">
                <PageAccount />
              </PrivateRoute>

              <PrivateRoute exact path="/integracoes">
                <PageIntegrationsNew />
              </PrivateRoute>

              <PrivateRoute exact path="/produtos">
                <PageProductsNew />
              </PrivateRoute>

              <PrivateRoute exact path="/produtos/:id/midias">
                <PageProductsNewMidias />
              </PrivateRoute>

              <PrivateRoute exact path="/produtos/:id/variacoes">
                <PageProductsNewVariants />
              </PrivateRoute>

              <PrivateRoute exact path="/pedidos">
                <PageSalesNew />
              </PrivateRoute>

              <PrivateRoute exact path="/pedidos/:id/produtos">
                <PageSalesNewProducts />
              </PrivateRoute>

              <PrivateRoute exact path="/galeria">
                <PageMediasNew />
              </PrivateRoute>

              <PrivateRoute exact path="/midias/:id/produtos">
                <PageMediasNewProducts />
              </PrivateRoute>

              <PrivateRoute exact path="/personalizar">
                <PageThemaNew />
              </PrivateRoute>

              <PrivateRoute exact path="/personalizar/email">
                <PageThemaNew />
              </PrivateRoute>

              <PrivateRoute exact path="/personalizar/checkout">
                <PageThemaNew />
              </PrivateRoute>

              <PrivateRoute exact path="/personalizar/page-checkout">
                <PageThemaNew />
              </PrivateRoute>

              <PrivateRoute exact path="/configuracoes">
                <PageSettings />
              </PrivateRoute>

              <PrivateRoute exact path="/passo-a-passo">
                <PageSteps />
              </PrivateRoute>


            </LayoutUI>
          </Switch>
        </Route>
      </Switch>

    </BrowserRouter>
  );
};

export default Routes;

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `/login`,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const isAuthenticated = () => {
  return localStorage.getItem("depoimentos@login") ||
    localStorage.getItem("depoimentosAdmin@login")
    ? true
    : false;
};
