import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";
import { isVisibleScript } from "../../../../utils/displayScript";
import FormEmail from "../../components/form-email";
import CustomEmail from "../../components/CustomEmail";
import CustomPage from "../../components/CustomPage";
import { BarLeft, BarLeftItem, Tooltip } from "./styled";
import { useHistory, useLocation } from "react-router-dom";
import { AiOutlineCloudDownload, AiOutlineMail } from "react-icons/ai";
import BoxEmail from "../../components/BoxEmail";
import BoxCheckout from "../../components/BoxCheckout";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import LoadingLogo from "../../../../components/Loading-logo";
import ModalInforVideo from "../../../../components/modal-infor-video";
import AlertCloseBoss from "../../../../components/alert-close-boss";
import { translations } from "../../../../utils/language";
import { FaCode } from "react-icons/fa";
import BoxPageCheckout from "../../components/BoxPageCheckout";
import HelpClick from "../../../../components/HelpClick";
import LayoutNew from "../../../../components/tailwind/layout-new";
import { ContainerNew } from "../../../dashboard/pages/home-new/styled";
import LazyLoadingThema from "../../../../components/lazy-loading-thema";
import LayoutUI from "../../../../components/tailwind/layout-ui";

const Home = () => {

  const selectedMenu = useLocation();
  const history = useHistory()
  const dispatch = useDispatch();

  const language = useSelector((state) => state.reducerLanguage);
  const [visibleModalInfor, setVisibleModalInfor] = useState(false)

  const controlConfig = useSelector((state) => state.reducerControlConfig);
  const config = useSelector((state) => state.reducerTemplate);

  useEffect(() => {
    dispatch({ type: "SAGA_GET_TEMPLATES", });
  }, []);





  const isSave = () => {

    if (
      config.data[0].color_checkout_background_header == controlConfig.color_checkout_background_header &&
      config.data[0].color_checkout_text_header == controlConfig.color_checkout_text_header &&
      config.data[0].color_checkout_background == controlConfig.color_checkout_background &&
      config.data[0].color_checkout_text_title == controlConfig.color_checkout_text_title &&
      config.data[0].color_checkout_background_product == controlConfig.color_checkout_background_product &&
      config.data[0].color_checkout_text_product == controlConfig.color_checkout_text_product &&
      config.data[0].color_button_checkout == controlConfig.color_button_checkout &&
      config.data[0].color_message_button_checkout == controlConfig.color_message_button_checkout &&

      config.data[0].checkout_message_header == controlConfig.checkout_message_header &&
      config.data[0].checkout_message_title == controlConfig.checkout_message_title &&
      config.data[0].checkout_message == controlConfig.checkout_message &&
      config.data[0].checkout_button == controlConfig.checkout_button &&
      config.data[0].checkout_button_link == controlConfig.checkout_button_link &&

      config?.data[0]?.color_email_background == controlConfig.color_email_background &&
      config?.data[0]?.color_title_email == controlConfig.color_title_email &&
      config?.data[0]?.color_button == controlConfig.color_button &&
      config?.data[0]?.color_message_button == controlConfig.color_message_button &&

      config?.data[0]?.subject_email == controlConfig.subject_email &&
      config?.data[0]?.message_email == controlConfig.message_email &&
      config?.data[0]?.message_button == controlConfig.message_button
    ) {
      dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_SET", payload: true })
      dispatch({ type: "CONFIG_CONTROL_SAVE_CHECKOUT_SET", payload: true })
      return true
    } else {
      dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_SET", payload: false })
      dispatch({ type: "CONFIG_CONTROL_SAVE_CHECKOUT_SET", payload: false })
      return false
    }
  }


  return (
    <>
      <ContainerNew>

        <ModalInforVideo visible={visibleModalInfor} setVisibleModal={setVisibleModalInfor} video={`https://www.youtube.com/embed/${language.data == "es" ? "YYt0LQL0zdc" : "IB4pkkzn9FU"}`} />

        {
          config.loading == false && config.data?.length > 0 ?
            <Contents >
              <div style={{ display: "flex", flexDirection: "row", width: "100%", minHeight: "calc(100vh - 240px)", }}>
                {/* <BarLeft isFull={true} >
                  <BarLeftItem style={{ position: "relative" }} active={selectedMenu.pathname == "/personalizar/checkout" || selectedMenu.pathname == "/personalizar" ? true : false} onClick={() => { isSave() && history.push("/personalizar/checkout") }}>
                    < AiOutlineCloudDownload />
                    <Tooltip>
                      Personalizar checkout
                    </Tooltip>
                  </BarLeftItem>

                  {JSON.parse(localStorage.getItem("depoimentos@login")).integration != undefined &&
                    JSON.parse(localStorage.getItem("depoimentos@login")).integration.platform.toLowerCase() == "nuvemshop" ?
                    <BarLeftItem style={{ position: "relative" }} active={selectedMenu.pathname == "/personalizar/page-checkout" ? true : false} onClick={() => { isSave() && history.push("/personalizar/page-checkout") }}>
                      <FaCode />
                      <Tooltip>
                        Página de downloads
                      </Tooltip>
                    </BarLeftItem> : null}


                  <BarLeftItem style={{ position: "relative" }} active={selectedMenu.pathname == "/personalizar/email" ? true : false} onClick={() => { isSave() && history.push("/personalizar/email") }}>
                    < AiOutlineMail />
                    <Tooltip>
                      Personalizar e-mail
                    </Tooltip>
                  </BarLeftItem>
                </BarLeft> */}
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                  {selectedMenu.pathname == "/personalizar/email" && <BoxEmail />}
                  {selectedMenu.pathname == "/personalizar" && <BoxCheckout />}
                  {selectedMenu.pathname == "/personalizar/checkout" && <BoxCheckout />}
                  {selectedMenu.pathname == "/personalizar/page-checkout" && <BoxPageCheckout />}
                </div>
              </div>
            </Contents> :
            <LazyLoadingThema />
            // <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
            //   <LoadingLogo />
            // </div>
        }

        {
          !config.loading &&
          <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
            <div onClick={() => { setVisibleModalInfor(true) }}>
              <HelpClick text={translations["alert-personalizar"][language.data]} />
            </div>
          </div>
        }

        {isVisibleScript(false)}
      </ContainerNew>
    </>
  );
};

export default Home;
