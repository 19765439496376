import React, { ReactNode, ReactElement, cloneElement, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useSubMenu } from '../SubMenuContext';
import { AnimatePresence, motion } from 'framer-motion';
import { makeID } from '../lib/utils';

interface ItemTextProps {
	children: ReactNode;
	className?: string;
}

const ItemText = ({ children }: ItemTextProps) => {
	const isValidReactElement = React.isValidElement(children);

	const [menuRandomID, setMenuRandomID] = useState('');

	useEffect(() => {
		setMenuRandomID(makeID());
	}, []);

	const { isSidebarOpen } = useSubMenu();

	const childWithClass = isValidReactElement ? (
		cloneElement(children as ReactElement<ItemTextProps>, {
			className: twMerge(`${(children as ReactElement<ItemTextProps>).props.className || ''}`),
		})
	) : (
		<span className="">{children}</span>
	);

	return (
		<>
			<AnimatePresence mode="wait">
				{isSidebarOpen ? (
					<motion.div key={menuRandomID} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.1 }} exit={{ opacity: 0 }}>
						{childWithClass}
					</motion.div>
				) : null}
			</AnimatePresence>
		</>
	);
};

export default ItemText;
