import React, { ReactNode, cloneElement, isValidElement, useEffect, useState } from 'react';
import { useSubMenu } from './SubMenuContext';
import { twMerge } from 'tailwind-merge';
import { makeID } from './lib/utils';

interface ItemSubProps {
	children: ReactNode;
	id?: string;
	asChild?: boolean;
	onClick?: React.MouseEventHandler<Element>;
	className?: string;
	draggable?: boolean;
}

const ItemSub = ({ children, id, asChild = false, className, ...props }: ItemSubProps) => {
	const { state, toggle, setSubId, clearSub } = useSubMenu();
	const [menuRandomID, setMenuRandomID] = useState('');

	useEffect(() => {
		setMenuRandomID(makeID());
	}, []);

	const handleClick: React.MouseEventHandler<Element> = (event) => {
		setSubId?.(menuRandomID);

		// se estiver abrindo um terceiro menu, ele vai remover o ativo do sub
		if (id) {
			toggle(id);
			clearSub?.();
		}

		if (props.onClick) {
			props.onClick(event);
		}
	};

	const isSubMenuActive = state?.subIdActive === menuRandomID;

	const defaultClasses = `w-full h-6 flex items-start justify-center flex-shrink-0`;
	const buttonDefaultClasses = `ml-10 flex-shrink-0 w-[calc(100%-40px)] h-full text-left py-0 px-1 break-normal whitespace-nowrap text-gray-900 font-medium text-xs rounded-md hover:bg-gray-100 cursor-pointer ${isSubMenuActive ? 'bg-gray-100' : ''} outline-none`;

	if (asChild && isValidElement(children)) {
		return cloneElement(children as React.ReactElement<ItemSubProps>, {
			onClick: handleClick,
			className: defaultClasses,
			children: <button className={twMerge(buttonDefaultClasses, className)}> {(children as React.ReactElement<ItemSubProps>).props.children}</button>,
			draggable: false,
			...props,
		});
	}

	return (
		<div onClick={handleClick} className={defaultClasses}>
			<button className={twMerge(buttonDefaultClasses, className)}>{children}</button>
		</div>
	);
};

export default ItemSub;
