import React, { useEffect, useState } from 'react'
import LayoutNew from '../../../../components/tailwind/layout-new'
import { ConatainerLoader, ContainerNew, Control } from './styled'
import { BoxColumn, BoxLine, Section, SectionLeft } from '../home/styled'
import Animation from '../../components/Animarion'
import Help from '../../components/Help'
import CardStatistic from '../../components/CardStatistic'
import Chart from '../../components/Chart'
import TopSales from '../../components/TopSales'
import { useDispatch, useSelector } from 'react-redux'
import LoadingLogo from '../../../../components/Loading-logo'
import SpaceInfor from '../../components/space-infor'
import BoxTrial from '../../components/trial'
import LazyLoadingDash from '../../../../components/lazy-loading-dash'
import LayoutUI from '../../../../components/tailwind/layout-ui'

const HomeNew = () => {

    const [qtd_links, set_qtd_links] = useState(null)
    const [qtd_downloads, set_qtd_downloads] = useState(null)

    const dispatch = useDispatch()

    const top = useSelector((state) => state.reducerTop);
    const productsInfor = useSelector((state) => state.reducerDashboardInforProducts);
    const mediasInfor = useSelector((state) => state.reducerDashboardInforMedias);
    const chart = useSelector((state) => state.reducerChart);

    useEffect(() => {
        let link = 0
        let download = 0
        top?.data?.downloads?.forEach(element => {
            if (element.type == "link") { link += element.occurrence }
            else if (element.type == "download") { download += element.occurrence }
        });
        set_qtd_downloads(download)
        set_qtd_links(link)
    }, [top])

    useEffect(() => {
        dispatch({ type: "SAGA_INFOR_DASHBOARD_MEDIAS" })
        dispatch({ type: "SAGA_INFOR_DASHBOARD_PRODUCTS" })
        dispatch({ type: "SAGA_INFOR_TOP" })
        dispatch({ type: "SAGA_INFOR_CHART" });
        dispatch({ type: "DASHBOARD_REQUEST" });
        dispatch({ type: "SAGA_LIST_SETTINGS", });
    }, [])

    return (
        <>
            <ContainerNew>

                {productsInfor.loading || mediasInfor.loading || chart.loading || top.loading ?
                    // <ConatainerLoader>
                    //     <LoadingLogo />
                    // </ConatainerLoader>
                    <LazyLoadingDash />
                    :
                    <>
                        <BoxLine>
                            <Animation />
                            <SectionLeft>
                                <Help />
                                {JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.cred_expire == 0 && <BoxTrial />}
                            </SectionLeft>
                        </BoxLine>
                        <BoxLine>
                            <BoxColumn>
                                <Section>
                                    <CardStatistic
                                        type="total"
                                        //data={dashboard?.data?.downloads?.length}
                                        data={qtd_downloads}
                                    />
                                    <CardStatistic
                                        type="products"
                                        //data={dashboard?.data?.links?.length}
                                        data={qtd_links}
                                    />
                                    <CardStatistic
                                        type="record"
                                        data={mediasInfor?.data?.data}
                                    />
                                    <CardStatistic
                                        type="space"
                                        data={productsInfor?.data?.data}
                                    />
                                </Section>
                                <Chart />
                            </BoxColumn>
                            <Control>
                                <SpaceInfor />
                                <TopSales />
                            </Control>

                        </BoxLine>
                    </>
                }

            </ContainerNew>
        </>
    )
}

export default HomeNew