import React, { useEffect } from 'react'
import LayoutNew from '../../../../components/tailwind/layout-new'
import { useParams } from 'react-router-dom';
import TableMedias from '../../../medias/components/tailwind/table-medias';
import Navbar from '../../components/tailwind/navbar';
import LayoutUI from '../../../../components/tailwind/layout-ui';

// import TableReviews from '../../components/tailwind/table-review';

const Medias = () => {

    //const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const { id } = useParams();

    useEffect(() => {
        console.log("midiaID", id)
    }, [])


    return (
        <>
            {/* <TableReviews productId={id} navbar={<Navbar />} /> */}
            <TableMedias productId={id} navbar={<Navbar />} />
        </>
    )
}

export default Medias