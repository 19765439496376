import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { EmpreenderApps } from '../lib/AppsArray';
import React, { useEffect, useState } from 'react';
import LoaderNavigationApps from './LoaderNavigationApps';
import { eplusHub } from '../api/eplus';
import HeaderApps from './HeaderApps';
import AppPreview from './AppPreview';
import EmpreenderPlusIconAlt from './appsIcons/AltEmpreenderPlus';
import { useSubMenu } from '../SubMenuContext';
import { Button } from './Button';

interface EplusApps {
	[key: string]: {
		integrations?: Integration;
	};
}

interface Integration {
	[key: string]: {
		show: boolean;
		img: string;
	};
}

const NavigationApps = () => {
	const [eplusApps, setEplusApps] = useState<EplusApps | null>(null);
	const { appEndPoint, headers } = useSubMenu();

	useEffect(() => {
		const options = {
			method: 'POST',
			credentials: 'include' as RequestCredentials,
			headers: headers || {},
			body: JSON.stringify({
				acao: 'buscarUsuarioComConfigs',
			}),
		};

		if (!eplusApps)
			eplusHub(options, appEndPoint).then((res) => {
				try {
					setEplusApps(res.data.apps);
				} catch (error) {
					setEplusApps({});
					return error;
				}
			});
	}, [eplusApps]);

	const contentClass = 'absolute h-full left-0 top-0 data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out data-[motion=from-end]:slide-in-from-top-52 data-[motion=from-start]:slide-in-from-bottom-52 data-[motion=to-end]:slide-out-to-top-52 data-[motion=to-start]:slide-out-to-bottom-52';

	return (
		<NavigationMenu.Root orientation="vertical" delayDuration={50} className="relative z-10 flex justify-center w-[250px] box-content pr-0.5">
			<NavigationMenu.List asChild>
				{eplusApps ? (
					<div className="custom-scroll max-h-[270px] overflow-y-auto overflow-x-hidden">
						<div className="center w-[250px] m-0 flex list-none rounded-md bg-white !p-1 flex-col">
							<NavigationMenu.Item className="w-full transition-all sticky top-0 bg-white">
								<NavigationMenu.Trigger className="w-full">
									<HeaderApps />
								</NavigationMenu.Trigger>
								<NavigationMenu.Content className={contentClass}>
									<ul className="w-[300px] !p-5 h-full">
										<div className="w-full flex flex-col gap-4 h-full justify-between">
											<div className="flex flex-row items-center gap-2">
												<div className="!p-2 !px-4 bg-gray-100 rounded-lg flex items-center justify-center">
													<span>
														<EmpreenderPlusIconAlt />
													</span>
												</div>
												<span className="text-gray-900 font-semibold break-normal whitespace-nowrap font-base"></span>
											</div>
											<p className="text-gray-900 text-sm">Assine +20 apps por apenas R$297/mês. Combine ferramentas, aproveite até 60% de desconto e simplifique sua gestão com praticidade e economia.</p>

											<div className="w-full flex flex-row justify-end">
												<Button variant='outline' asChild>
													<a href="https://app.empreender.plus" target="_blank">
														Saiba mais
													</a>
												</Button>
											</div>
										</div>
									</ul>
								</NavigationMenu.Content>
							</NavigationMenu.Item>
							{EmpreenderApps.map((app, index) => (
								<React.Fragment key={index}>
									<NavigationMenu.Item>
										<NavigationMenu.Trigger asChild className="group flex select-none items-center w-full justify-between gap-0.5 rounded px-3 py-2 text-[15px] font-medium leading-none text-gray-900 outline-none">
											<a href="#" className={`flex flex-row h-8 rounded-md items-center justify-between !p-2 gap-2 hover:bg-gray-100 transition cursor-pointer`}>
												<div className="flex flex-row gap-2 items-center">
													<span>{app.icon}</span>
													<span className="text-gray-800 text-xs font-normal">{app.name}</span>
												</div>
											</a>
										</NavigationMenu.Trigger>
										<NavigationMenu.Content className={contentClass}>
											<AppPreview app={app} eplusApps={eplusApps} />
										</NavigationMenu.Content>
									</NavigationMenu.Item>
								</React.Fragment>
							))}
							<NavigationMenu.Indicator className="top-full z-10 flex h-2.5 items-end justify-center overflow-hidden transition-[width,transform_250ms_ease]">
								<div className="relative top-[70%] size-2.5 rotate-45 rounded-tl-sm bg-white" />
							</NavigationMenu.Indicator>
						</div>
					</div>
				) : (
					<LoaderNavigationApps />
				)}
			</NavigationMenu.List>

			<div className="perspective-[2000px] absolute top-[-6px] left-[259px] flex w-[300px] justify-center h-[282px] !pointer-events-auto">
				<NavigationMenu.Viewport className="pointer-events-auto relative h-[282px] w-[300px] origin-[top_center] overflow-hidden rounded-md bg-white data-[state=open]:border border-gray-100 data-[state=open]:animate-in duration-150 data-[state=closed]:animate-out data-[state=closed]:slide-in-right-[10px] data-[state=closed]:fade-out data-[state=open]:fade-in data-[state=open]:slide-in-from-left-[10px]" />
			</div>
		</NavigationMenu.Root>
	);
};

export default NavigationApps;
