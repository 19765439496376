import React from 'react'
import LayoutNew from '../../../../components/tailwind/layout-new'
import { ContainerNew } from '../../../dashboard/pages/home-new/styled'
import TableOrders from '../../components/tailwind/table'
import TableProducts from '../../components/tailwind/table-products'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import LayoutUI from '../../../../components/tailwind/layout-ui'

const Products = () => {

    const order = useSelector((state) => state.reducerGetOrder);
    const dispatch = useDispatch()
    const [products, setProducts] = useState(null)

    const { id } = useParams();

    useEffect(() => { dispatch({ type: "SAGA_GET_SALE", payload: id, }); }, [])

    useEffect(() => {
        console.log("order", order)
        if (order?.data?.data?.order_items) {
            setProducts(JSON.parse(order?.data?.data?.order_items))
        }
    }, [order])

    return (
        <>
            <ContainerNew>
                <TableProducts
                    products={products}
                    order={order?.data?.data?.order_number}
                />
            </ContainerNew>
        </>
    )
}

export default Products