import styled, { keyframes } from "styled-components";

const progress = keyframes`
  /* 0% { background-color: #fff; width: 0%; }
  100% { background-color: #fff; width: 100%; } */

  0% { 
    background-position: -100%; 
  }
  100% { 
    background-position: 200%; 
  }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    //background-color: red;
    height: calc(100vh - 140px);
    overflow: hidden;
    padding: 0px 20px;
`

export const Table = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: column;
    //background-color: red;
    position: relative;
    /* &::after {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        //background-color: red; 
        border-radius: 4px;
        animation: ${progress} 1s infinite;
    } */
`

export const TableTitle = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    background-color: #f2f2f2;
    position: relative;
    border-radius: 4px;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.8) 50%, transparent 100%); /* Linha de luz */
        background-size: 200% 100%;
        animation: ${progress} 2s infinite;
        border-radius: 4px;
    }
`

export const TableContents = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

export const TableTitleItem = styled.div`
    display: flex;
    width: 100%;
    max-width: 300px;
    background-color: #f2f2f2;
    min-height: 40px;
    //position: relative;
    border-radius: 4px;

   
`

export const TableLineItem = styled.div`
    display: flex;
    background-color: #f2f2f2;
    //border: 1px solid #ccc; 
    width: 100%;
    position: relative;
    height: 30px;
    border-radius: 4px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.8) 50%, transparent 100%); /* Linha de luz */
        background-size: 200% 100%;
        animation: ${progress} 2s infinite;
        border-radius: 4px;
    }


    
`
export const TableLine = styled.div`
    display: flex;
    //background-color: yellow;
    width: 100%;
    gap: 60px;
    min-height: 24px;

`

export const Title = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    div{
        display: flex;
        width: 100%;
        max-width: 300px;
        min-height: 40px;
        border-radius: 4px;
        background-color: #f2f2f2;
        position: relative;

        
        /* &::after {
            content: '';
            position: absolute;
            top: 0;
            height: 100%;
            //background-color: red; 
            border-radius: 4px;
            animation: ${progress} 1s infinite;
        } */

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.8) 50%, transparent 100%); /* Linha de luz */
                background-size: 200% 100%;
                animation: ${progress} 2s infinite;
                border-radius: 4px;
            }
    }

    span{
        display: flex;
        min-width: 90%;
        min-height: 30px;
        background-color: #f2f2f2;
        border-radius: 4px;
        position: relative;
        
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.8) 50%, transparent 100%); /* Linha de luz */
            background-size: 200% 100%;
            animation: ${progress} 2s infinite;
            border-radius: 4px;
        }
    }

    
`



